import { Component, Mixins } from "vue-property-decorator";

import DashboardLayoutMixin from "../../common/mixins/dashboard-layout.mixin";

import AppBar from "./components/AppBar/AppBar.vue";

@Component({
  components: {
    AppBar,
    MainProductTour: () =>
      import("@/components/MainProductTour/MainProductTour.vue"),
  },
})
export default class DesktopDashboardLayout extends Mixins(
  DashboardLayoutMixin
) {
  private mounted() {
    this.initFeedNewsWatcher();
    this.initMessagesSSE();
  }
}
