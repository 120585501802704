import { State } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { APP_BAR_HEIGHT } from "@config/base";
import {
  profileModule,
  newsModule,
  authModule,
  messageModule,
} from "@store/namespaces";
import { ProfileGetters } from "@store/modules/profile/types";
import { NewsGetters } from "@store/modules/news/types";
import { AuthActions } from "@store/modules/auth/types";
import { Role } from "@/types/role";
import { RootState } from "@store/types";
import { HtmlElementId } from "@/types/element";
import { MessageGetters } from "@store/modules/message/types";

@Component({
  components: {
    HeaderWallets: () => import("@/components/HeaderWallets/HeaderWallets.vue"),
    HeaderStatDeclines: () =>
      import("@/components/HeaderStatDeclines/HeaderStatDeclines.vue"),
  },
})
export default class AppBar extends Vue {
  @State
  private readonly enabledNewYearDesign!: RootState["enabledNewYearDesign"];
  @profileModule.Getter
  private readonly canViewFacebookCodeNotifications!: ProfileGetters["canViewFacebookCodeNotifications"];
  @profileModule.Getter("userHasRole")
  private readonly userHasRoleGetter!: ProfileGetters["userHasRole"];
  @authModule.Action private readonly logOut!: AuthActions["logOut"];
  @profileModule.Getter("profileFirstName")
  private readonly profileFirstNameGetter!: ProfileGetters["profileFirstName"];
  @profileModule.Getter("profileAvatar")
  private readonly profileAvatarGetter!: ProfileGetters["profileAvatar"];
  @profileModule.Getter("accessToProfilePage")
  private readonly accessToProfilePageGetter!: ProfileGetters["accessToProfilePage"];
  @newsModule.Getter("feedNewsCount")
  private readonly feedNewsCountGetter!: NewsGetters["feedNewsCount"];
  @newsModule.Getter("countNotReadNews")
  private readonly countNotReadNewsGetter!: NewsGetters["countNotReadNews"];
  @messageModule.Getter("countNotReadMessages")
  private readonly countNotReadMessagesGetter!: MessageGetters["countNotReadMessages"];

  private get countNotReadNotifications() {
    if (this.canViewFacebookCodeNotifications) {
      return this.countNotReadNewsGetter + this.countNotReadMessagesGetter;
    }

    return this.countNotReadNewsGetter;
  }

  private get appBarHeight() {
    return APP_BAR_HEIGHT;
  }

  private get isShowHeaderStatDeclines() {
    return this.userHasRoleGetter([
      Role.ROLE_TEAMLEAD,
      Role.ROLE_MEDIABUYER,
      Role.ROLE_OWNER,
      Role.ROLE_ACCOUNTANT,
    ]);
  }

  private get htmlElementId() {
    return {
      headerBellButton: HtmlElementId.headerBellButton,
    };
  }

  private onClickNotifications() {
    this.$emit("click:notifications");
  }

  private onClickAvatar() {
    this.$emit("click:avatar");
  }
}
